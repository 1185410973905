@import "../../../App.css";

.dropdown-menu-trigger {
  display: flex;
  height: 36px;
  width: 200px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: var(--space-md);
  padding: 10px var(--font-size-sm);
  background-color: var(--color-base-white);
  border: 1px solid var(--color-gray-300);
  border-radius: var(--radius-xs);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-family: var(--font-family-body);
}

.dropdown-menu-trigger:hover {
  border-color: var(--color-gray-400);
}

.dropdown-menu-icon-only {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.dropdown-menu-icon-only:focus,
.dropdown-menu-icon-only:active,
.dropdown-menu-icon-only:focus-visible {
  border: none;
  outline: none;
}

.dropdown-menu-custom-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-gray-500);

  width: 24px;
  height: 24px;
  padding: 4px;
  border-radius: var(--radius-full, 9999px);
  transition: background-color 0.2s ease-in-out;
}

.dropdown-menu-icon-only:hover .dropdown-menu-custom-icon,
.dropdown-menu-icon-only[data-state="open"] .dropdown-menu-custom-icon {
  background: var(--colors-gray-light-mode-200, #e4e7ec);
}

.dropdown-menu-trigger:focus {
  outline: none;
}

.dropdown-menu-trigger-icon {
  width: 20px;
  height: 20px;
  color: var(--color-gray-500);
  transition: transform 0.2s ease-in-out;
}

.dropdown-menu-trigger[data-state="open"] .dropdown-menu-trigger-icon {
  transform: rotate(180deg);
}

.dropdown-menu-trigger-text {
  overflow: hidden;
  color: var(--color-gray-800); /* Rebecca want to the color more black in the text */
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: var(--font-family-body);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-md);
}

.dropdown-menu-content,
.dropdown-menu-sub-content {
  width: 200px;
  border-radius: 4px;
  padding: 4px 0;
  border: 1px solid var(--color-gray-200);
  background: var(--color-base-white);
  box-shadow:
    0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  z-index: 50;
  overflow: hidden;
}

.dropdown-menu-item,
.dropdown-menu-checkbox-item,
.dropdown-menu-radio-item,
.dropdown-menu-sub-trigger,
.dropdown-menu-label-item,
.dropdown-menu-label {
  display: block;
  outline: none;
  padding: 1px 6px;
}

/* the dropdown menu selection item */
.dropdown-menu-item-inner {
  display: flex;
  padding: 9px 10px;
  align-items: center;
  flex: 1 0 0;
  border-radius: 4px;
  font-family: var(--font-family-body);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-sm);
  color: var(--color-gray-700);
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
  transition:
    background-color 0.2s ease-in-out,
    color 0.2s ease-in-out;
}

.dropdown-menu-item-inner-sub-trigger {
  justify-content: space-between;
}

.dropdown-menu-item:hover .dropdown-menu-item-inner,
.dropdown-menu-checkbox-item:hover .dropdown-menu-item-inner,
.dropdown-menu-radio-item:hover .dropdown-menu-item-inner,
.dropdown-menu-sub-trigger:hover .dropdown-menu-item-inner {
  background: var(--color-secondary-100, #ccfbef);
  color: var(--color-gray-800);
}

/* the dropdown menu selection item without checkbox and radio button hover effect*/
.dropdown-menu-label-item:hover .dropdown-menu-item-inner {
  border-color: #2ED3B7;
  background: #F0FDF9;
  color: var(--color-gray-800);
}

/* the dropdown menu selection item without checkbox and radio button click effect*/
.dropdown-menu-label-item:active .dropdown-menu-item-inner{
  background-color: #c5f0e3;
  border-color: #2ED3B7;
  cursor: pointer;
}

.dropdown-menu-item[data-disabled] .dropdown-menu-item-inner,
.dropdown-menu-checkbox-item[data-disabled] .dropdown-menu-item-inner,
.dropdown-menu-radio-item[data-disabled] .dropdown-menu-item-inner,
.dropdown-menu-label-item[data-disabled] .dropdown-menu-item-inner,
.dropdown-menu-sub-trigger[data-disabled] .dropdown-menu-item-inner {
  color: var(--color-gray-400);
  cursor: not-allowed;
}

.dropdown-menu-checkbox-indicator,
.dropdown-menu-radio-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.dropdown-menu-checkbox-icon,
.dropdown-menu-radio-icon {
  width: 12px;
  height: 12px;
}

.dropdown-menu-radio-icon {
  background-color: var(--color-primary);
  border-radius: 50%;
}

.dropdown-menu-label .dropdown-menu-item-inner {
  color: var(--color-gray-500);
}

.dropdown-menu-separator {
  height: 0.5px;
  margin: 4px 0;
  background-color: var(--color-gray-200);
}

.dropdown-menu-shortcut {
  margin-left: auto;
  font-size: var(--font-size-xs);
  color: var(--color-gray-500);
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.dropdown-menu-content {
  animation: scaleIn 0.2s ease-out;
}

.dropdown-menu-content[data-filter-menu],
.dropdown-menu-sub-content[data-filter-menu] {
  width: 250px;
  max-height: 500px;
  overflow-y: auto;
}

.dropdown-menu-content[data-filter-menu]::-webkit-scrollbar,
.dropdown-menu-sub-content[data-filter-menu]::-webkit-scrollbar {
  width: 8px;
}

.dropdown-menu-content[data-filter-menu]::-webkit-scrollbar-track,
.dropdown-menu-sub-content[data-filter-menu]::-webkit-scrollbar-track {
  background: var(--color-gray-100);
}

.dropdown-menu-content[data-filter-menu]::-webkit-scrollbar-thumb,
.dropdown-menu-sub-content[data-filter-menu]::-webkit-scrollbar-thumb {
  background: var(--color-gray-300);
  border-radius: 4px;
}

.dropdown-menu-content[data-filter-menu] {
  width: 250px;
  height: 500px;
  overflow-y: auto;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 1px solid var(--color-gray-200);
  display: flex;
  flex-direction: column;
}

.dropdown-menu-content[data-filter-menu] > div {
  min-height: auto; /* Change from 100% to auto */
  display: flex;
  flex-direction: column;
}

.filter-menu-container {
  display: flex;
  width: 500px;
}

.dropdown-menu-content[data-filter-menu] {
  width: 250px;
  height: 500px;
  overflow-y: auto;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 1px solid var(--color-gray-200);
  display: flex;
  flex-direction: column;
}

.dropdown-menu-sub-content[data-filter-menu] {
  width: 250px;
  height: 500px; /* Match parent height */
  overflow-y: auto;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 1px solid var(--color-gray-200);
  margin-left: -1px; /* Overlap borders */
  background-color: var(--color-base-white);
  box-shadow: 4px 0 8px -4px rgba(16, 24, 40, 0.08);
}

.dropdown-menu-content[data-filter-menu] > div,
.dropdown-menu-sub-content[data-filter-menu] > div {
  min-height: auto;
  display: flex;
  flex-direction: column;
}

.dropdown-menu-sub-content[data-filter-menu] {
  display: flex;
  flex-direction: column;
  height: 500px;
}

.dropdown-menu-sub-trigger[data-state="active"] .dropdown-menu-item-inner,
.dropdown-menu-sub-trigger[data-active="true"] .dropdown-menu-item-inner {
  background: var(--color-secondary-100, #ccfbef);
  color: var(--color-gray-800);
}

.dropdown-menu-sub-trigger:hover .dropdown-menu-item-inner {
  background: var(--color-secondary-100, #ccfbef);
  color: var(--color-gray-800);
}
