/* The Chart Card used for achieve modul all the chart */
/* Chart Card: Ahieve module Chart Card with chart data item display list */
.ChartCard{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: var(--font-family-display);
    border: 1px solid var(--colors-gray-light-mode-100, #F2F4F7);;
}

/* dropdown menu with View Details button */
.chartMenuArea{
    display: flex;
    padding: 24px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid #F2F4F7;
}

.detailsText{
    color: var(--blue-600);
    font-size: var(--font-size-xs);
    font-style: normal;
    font-weight: 600;
    line-height: var(--line-height-xs); /* 150% */
    cursor: pointer;
}

/* the chart area including chart */
.chartArea{
    display: flex;
    justify-content: center;
    align-self: center;
    padding: 12px 24px;
    height: calc(65% - 150px);
    align-self: stretch;
    align-items: flex-start;
    border-radius: 0px 0px 4px 4px;
    background: var(--color-base-white);
}

/* the chart area data item list */
.chartDataDeatils{
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   justify-content: space-between;
   flex: 1;
   align-self: stretch;
   padding: 12px 24px;
   padding-top: 0px;
}

/* The chartDataDeatils title */
.chartTitle{
    font-size: var(--font-size-sm);
    font-style: normal;
    font-weight: 600;
    line-height: var(--line-height-sm); /* 142.857% */
    color: var(--color-gray-600);
}

/*The chartDataDeatils sub title*/
.chartSubTitle{
    font-size: var(--font-size-display-sm);
    font-style: normal;
    font-weight: 600;
    line-height: var(--line-height-display-sm); /* 142.857% */
    color: var(--color-gray-800);
    margin-top: 6px;
}

/*The chartDataDeatils data item area: color, label, counts and Percentage*/
.dataList {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  gap: 12px;
  margin-top: 14px;
  overflow-y: auto;
}

/*The chartDataDeatils data item list (one row): color, label, counts and Percentage*/
.dataItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/*The chartDataDeatils data item list (one row left): color, label*/
.dataItemLeft {
  display: flex;
  align-items: center;
  gap: 8px;
}

/*The chartDataDeatils data item list: the left color dot*/
.colorDot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

/*The chartDataDeatils data item list: the label*/
.dataLabel {
  font-size: 14px;
}

.dataInfo {
  display: flex;
  gap: 8px;
  font-size: 14px;
}

.dataCount {
  font-weight: 500;
}

.dataPercentage {
  color: #6b7280;
}