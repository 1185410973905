/* Achieve moduel: Dashboard Part */
.dashboardArea{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: calc(100vw - 52px);
    min-height: calc(100vh - 40px);
    max-height: calc(100vh - 40px);
    padding: 24px;
    gap: 24px;
    flex: 1 0 0;
    overflow-x: hidden;
    overflow-y: auto;
}

/* the scorll bar of dashboard area*/
.dashboardArea::-webkit-scrollbar {
  width: 4px !important;
}

.dashboardArea::-webkit-scrollbar-track {
  background: white !important;
}

.dashboardArea::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.chartOverview{
  display: flex;
  align-items: flex-start;
  gap: 12px;
}